import { useProfile } from 'hooks/useContext'
import React from 'react'
import clsx from 'clsx'
import { Typography, Button } from 'antd'

import { MailModal } from './modals/MailModal'
import { PasswordModal } from './modals/PasswordModal'

import styles from './styles.module.scss'
import { useState } from 'react'

import { useChangeProfile } from 'api/Profile'
import { useNotification } from 'hooks/useNotification'
import { ChatIdModal } from './modals/ChatIdModal'

const { Title } = Typography

export const Profile = () => {
  const { user } = useProfile()
  const { changeNotification } = useChangeProfile()
  const { createNotification } = useNotification()

  const [mailModalOpen, setMailModalOpen] = useState(false)
  const [passModalOpen, setPassModalOpen] = useState(false)
  const [chatIdModalOpen, setChatIdModalOpen] = useState(false)

  const handleChangePassword = () => setPassModalOpen(true)
  const handleChangeEmail = () => setMailModalOpen(true)
  const handleChangeChatId = () => setChatIdModalOpen(true)

  const handleCloseMailModal = () => setMailModalOpen(false)
  const handleClosePassModal = () => setPassModalOpen(false)
  const handleCloseChatId = () => setChatIdModalOpen(false)

  const handleChangeNotification = async () => {
    // console.log(user)
    const res = await changeNotification(user.id, user.notification)
    createNotification(res, 'success')
  }

  return (
    <div className={styles.profileContainer}>
      <div className={styles.topContainer}>
        <Title level={3}>
          Профиль пользователя <i>{user.name}</i>
        </Title>
      </div>

      <div className={styles.profileInfo}>
        <p>Почта:</p>
        <p>{user.email}</p>
        <Button type='primary' onClick={handleChangeEmail}>
          Изменить
        </Button>

        <p>Пароль:</p>
        <p>
          &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
        </p>
        <Button type='primary' onClick={handleChangePassword}>
          Изменить
        </Button>

        <p>Telegram chat ID:</p>
        <p>{user?.chatId || 'Отсутствует'}</p>
        <Button type='primary' onClick={handleChangeChatId}>
          Изменить
        </Button>

        <p>Email уведомления:</p>
        <p className={clsx(user.notification ? styles.green : styles.red)}>
          {user.notification ? 'Включены' : 'Отключены'}
        </p>
        <Button type='primary' onClick={handleChangeNotification}>
          Изменить
        </Button>
      </div>

      <MailModal
        isOpen={mailModalOpen}
        onClose={handleCloseMailModal}
        user={user}
      />
      <PasswordModal
        isOpen={passModalOpen}
        onClose={handleClosePassModal}
        user={user}
      />

      <ChatIdModal
        isOpen={chatIdModalOpen}
        onClose={handleCloseChatId}
        user={user}
      />
    </div>
  )
}
