import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Table,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  message,
  InputNumber
} from 'antd'

import Error from 'components/Error'
import MultiSelect from 'components/MultiSelect'

import axios from 'utils/axios'

import styles from './styles.module.scss'
import { UnlockOutlined, LockOutlined } from '@ant-design/icons'

const { Title } = Typography

const ACTIONS = {
  EDIT: 'edit',
  BLOCK: 'block'
}

const Users = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalBlockOpen, setModalBlockOpen] = useState(false)
  const [user, setUser] = useState(null)

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/user')
      setData(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        id: 2,
        title: 'Статус',
        key: 'id',
        dataIndex: 'id',
        render: (text, record) => (
          <div className={styles.tableButtons}>
            {record.blocked ? (
              <LockOutlined className={styles.iconLock} />
            ) : (
              <UnlockOutlined className={styles.iconUnLock} />
            )}
          </div>
        )
      }
    ],
    []
  )

  const handleModalUser = (data, action) => {
    setUser(data)

    if (action === ACTIONS.EDIT) {
      setModalOpen(true)
    } else {
      setModalBlockOpen(true)
    }
  }

  const handleOpenModal = () => {
    setUser(null)
    setModalOpen(true)
  }

  const handleJustCloseModal = (action) => {
    setUser(null)

    if (action === ACTIONS.EDIT) {
      setModalOpen(false)
    } else {
      setModalBlockOpen(false)
    }
  }

  const handleOnCloseModal = () => {
    setUser(null)
    setModalOpen(false)
    getData()
  }

  const handleOnUpdate = (data) => {
    setData((state) => {
      return state.map((user) => {
        if (user.id === data.id) {
          return data
        }
        return user
      })
    })

    setUser(data)
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title className={styles.title} level={2}>
          Список учётных записей
        </Title>
        <Button type='primary' onClick={handleOpenModal}>
          Добавить пользователя
        </Button>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={false}
        rowClassName={styles.row}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleModalUser(record, ACTIONS.EDIT)
            }
          }
        }}
      />
      <UserModal
        user={user}
        justClose={() => handleJustCloseModal(ACTIONS.EDIT)}
        onClose={() => handleOnCloseModal(ACTIONS.EDIT)}
        update={(data) => handleOnUpdate(data)}
        isOpen={modalOpen}
      />
    </div>
  )
}

export default Users

const UserModal = (props) => {
  const { user, isOpen, onClose, justClose, update } = props
  const isEdit = user?.id
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  const getRoles = useCallback(async () => {
    const res = await axios.get('/role')
    setRoles(res.data)
  }, [])

  useEffect(() => {
    if (!roles.length) {
      getRoles()
    }
  }, [getRoles, roles.length])

  const optionsRoles = useMemo(() => {
    return roles.map((item) => ({ id: item.id, label: item.name }))
  }, [roles])

  const handleSave = async (value) => {
    try {
      setLoading(true)

      isEdit
        ? await axios.put(`/user/${user.id}`, value)
        : await axios.post('/user/create', value)
      resetForm()

      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleBlockUser = React.useCallback(async () => {
    try {
      setLoading(true)

      const res = await axios.put(`/user/blocked/${user.id}`, {
        blocked: !user.blocked
      })

      message.success(
        `Пользователь ${res.data.name} ${
          res.data.blocked ? 'Заблокирован' : 'Разблокирован'
        }`
      )

      update(res.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [user])

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
    useFormik({
      onSubmit: (values) => handleSave(values),
      initialValues: {
        name: user ? user.name : '',
        email: user ? user.email : '',
        password: '',
        repPass: '',
        chatId: user?.chatId ? Number(user.chatId) : null,
        roles:
          isEdit && user.roles.length ? user.roles.map((role) => role.id) : []
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        email: yup
          .string()
          .required('Это поле обязательно')
          .email('Не верный формат почты'),

        ...(!isEdit && {
          password: yup.string().required('Это поле обязательно'),
          repPass: yup
            .string()
            .required('Это поле обязательно')
            .test('match', 'Пароли не совпадают', function (repPass) {
              if (repPass && this.parent.password !== '') {
                if (repPass === this.parent.password) {
                  return true
                } else {
                  return false
                }
              }
            })
        }),
        chatId: yup.string(),
        roles: yup
          .array()
          .min(1, 'Хотя бы одна роль')
          .required('Это поле обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = (name) => (event) => {
    setFieldValue(name, event.target.value)
  }
  const handleChangeNumber = (name) => (event) => {
    setFieldValue(name, event)
  }

  const handleChangeSelect = (name) => (value) => {
    setFieldValue(name, value)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={justClose}>
      <div className={styles.formContainer}>
        <Form style={{ width: '100%' }} layout='vertical'>
          {isEdit && (
            <Form.Item>
              <Popconfirm
                title={
                  user.blocked
                    ? 'Сотрудник будет разблокирован!'
                    : 'Сотрудник будет заблокирован и не сможет зайти в систему!'
                }
                okText={'Подтвердить'}
                cancelText={'Отмена'}
                onConfirm={() => handleBlockUser()}
              >
                <Button
                  loading={loading}
                  type={!user.blocked ? 'danger' : 'primary'}
                >
                  {!user.blocked ? 'Заблокировать' : 'Разблокировать'}
                </Button>
              </Popconfirm>
            </Form.Item>
          )}

          <Form.Item
            label='Telegram chat ID'
            extra={touched.chatId && <Error message={errors.chatId} />}
          >
            <InputNumber
              placeholder='Chat ID'
              value={values.chatId}
              onChange={handleChangeNumber('chatId')}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label='Имя'
            extra={touched.name && <Error message={errors.name} />}
          >
            <Input
              placeholder='Иванов Иван'
              value={values.name}
              onChange={handleChange('name')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item
            label='Email'
            extra={touched.email && <Error message={errors.email} />}
          >
            <Input
              placeholder='ivanov.i@gmail.com'
              value={values.email}
              onChange={handleChange('email')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          {!isEdit && (
            <Form.Item
              label='Пароль'
              extra={touched.password && <Error message={errors.password} />}
            >
              <Input.Password
                value={values.password}
                onChange={handleChange('password')}
                autoSize={{ minRows: 7 }}
              />
            </Form.Item>
          )}

          {!isEdit && (
            <Form.Item
              label='Повторите пароль'
              extra={touched.repPass && <Error message={errors.repPass} />}
            >
              <Input.Password
                value={values.repPass}
                onChange={handleChange('repPass')}
                autoSize={{ minRows: 7 }}
              />
            </Form.Item>
          )}

          <Form.Item
            label='Роль'
            extra={touched.roles && <Error message={errors.roles} />}
          >
            <MultiSelect
              value={values.roles}
              placeholder='Роли сотрудника'
              options={optionsRoles}
              mode='multiple'
              onChange={handleChangeSelect('roles')}
              autoSize={{ minRows: 7 }}
            />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type='primary' onClick={handleSubmit}>
              {isEdit ? 'Cохранить' : 'Создать'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
